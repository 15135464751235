.itwgo-30x20_2J {    width: 30px; height: 20px;    background: url('css_sprites.png') -1130px -670px;}
.itwgo-30x20_2Z {    width: 30px; height: 20px;    background: url('css_sprites.png') -1130px -710px;}
.itwgo-30x20_B3 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1130px -750px;}
.itwgo-30x20_B5 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1130px -790px;}
.itwgo-30x20_B6 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1130px -830px;}
.itwgo-30x20_B7 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1130px -870px;}
.itwgo-30x20_BA {    width: 30px; height: 20px;    background: url('css_sprites.png') -1130px -910px;}
.itwgo-30x20_BB {    width: 30px; height: 20px;    background: url('css_sprites.png') -1130px -950px;}
.itwgo-30x20_BE {    width: 30px; height: 20px;    background: url('css_sprites.png') -1130px -990px;}
.itwgo-30x20_BH {    width: 30px; height: 20px;    background: url('css_sprites.png') -1130px -1030px;}
.itwgo-30x20_BJ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1130px -1070px;}
.itwgo-30x20_BP {    width: 30px; height: 20px;    background: url('css_sprites.png') -10px -1110px;}
.itwgo-30x20_BT {    width: 30px; height: 20px;    background: url('css_sprites.png') -60px -1110px;}
.itwgo-30x20_BV {    width: 30px; height: 20px;    background: url('css_sprites.png') -110px -1110px;}
.itwgo-30x20_BW {    width: 30px; height: 20px;    background: url('css_sprites.png') -160px -1110px;}
.itwgo-30x20_BX {    width: 30px; height: 20px;    background: url('css_sprites.png') -210px -1110px;}
.itwgo-30x20_CO {    width: 30px; height: 20px;    background: url('css_sprites.png') -260px -1110px;}
.itwgo-30x20_CU {    width: 30px; height: 20px;    background: url('css_sprites.png') -310px -1110px;}
.itwgo-30x20_CX {    width: 30px; height: 20px;    background: url('css_sprites.png') -360px -1110px;}
.itwgo-30x20_CY {    width: 30px; height: 20px;    background: url('css_sprites.png') -410px -1110px;}
.itwgo-30x20_CZ {    width: 30px; height: 20px;    background: url('css_sprites.png') -460px -1110px;}
.itwgo-30x20_D2 {    width: 30px; height: 20px;    background: url('css_sprites.png') -510px -1110px;}
.itwgo-30x20_D6 {    width: 30px; height: 20px;    background: url('css_sprites.png') -560px -1110px;}
.itwgo-30x20_D7 {    width: 30px; height: 20px;    background: url('css_sprites.png') -610px -1110px;}
.itwgo-30x20_DC {    width: 30px; height: 20px;    background: url('css_sprites.png') -660px -1110px;}
.itwgo-30x20_DE {    width: 30px; height: 20px;    background: url('css_sprites.png') -710px -1110px;}
.itwgo-30x20_DG {    width: 30px; height: 20px;    background: url('css_sprites.png') -760px -1110px;}
.itwgo-30x20_DL {    width: 30px; height: 20px;    background: url('css_sprites.png') -810px -1110px;}
.itwgo-30x20_DN {    width: 30px; height: 20px;    background: url('css_sprites.png') -860px -1110px;}
.itwgo-30x20_DV {    width: 30px; height: 20px;    background: url('css_sprites.png') -910px -1110px;}
.itwgo-30x20_DX {    width: 30px; height: 20px;    background: url('css_sprites.png') -960px -1110px;}
.itwgo-30x20_DY {    width: 30px; height: 20px;    background: url('css_sprites.png') -1010px -1110px;}
.itwgo-30x20_E7 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1060px -1110px;}
.itwgo-30x20_EI {    width: 30px; height: 20px;    background: url('css_sprites.png') -1110px -1110px;}
.itwgo-30x20_EL {    width: 30px; height: 20px;    background: url('css_sprites.png') -1160px -1110px;}
.itwgo-30x20_EQ {    width: 30px; height: 20px;    background: url('css_sprites.png') -10px -1150px;}
.itwgo-30x20_EK {    width: 30px; height: 20px;    background: url('css_sprites.png') -60px -1150px;}
.itwgo-30x20_ET {    width: 30px; height: 20px;    background: url('css_sprites.png') -110px -1150px;}
.itwgo-30x20_EY {    width: 30px; height: 20px;    background: url('css_sprites.png') -160px -1150px;}
.itwgo-30x20_0B {    width: 30px; height: 20px;    background: url('css_sprites.png') -210px -1150px;}
.itwgo-30x20_1X {    width: 30px; height: 20px;    background: url('css_sprites.png') -260px -1150px;}
.itwgo-30x20_2I {    width: 30px; height: 20px;    background: url('css_sprites.png') -310px -1150px;}
.itwgo-30x20_2K {    width: 30px; height: 20px;    background: url('css_sprites.png') -360px -1150px;}
.itwgo-30x20_2L {    width: 30px; height: 20px;    background: url('css_sprites.png') -410px -1150px;}
.itwgo-30x20_2M {    width: 30px; height: 20px;    background: url('css_sprites.png') -460px -1150px;}
.itwgo-30x20_2N {    width: 30px; height: 20px;    background: url('css_sprites.png') -510px -1150px;}
.itwgo-30x20_2T {    width: 30px; height: 20px;    background: url('css_sprites.png') -560px -1150px;}
.itwgo-30x20_3E {    width: 30px; height: 20px;    background: url('css_sprites.png') -610px -1150px;}
.itwgo-30x20_3L {    width: 30px; height: 20px;    background: url('css_sprites.png') -660px -1150px;}
.itwgo-30x20_3M {    width: 30px; height: 20px;    background: url('css_sprites.png') -710px -1150px;}
.itwgo-30x20_3P {    width: 30px; height: 20px;    background: url('css_sprites.png') -760px -1150px;}
.itwgo-30x20_3S {    width: 30px; height: 20px;    background: url('css_sprites.png') -810px -1150px;}
.itwgo-30x20_3U {    width: 30px; height: 20px;    background: url('css_sprites.png') -860px -1150px;}
.itwgo-30x20_4M {    width: 30px; height: 20px;    background: url('css_sprites.png') -910px -1150px;}
.itwgo-30x20_4O {    width: 30px; height: 20px;    background: url('css_sprites.png') -960px -1150px;}
.itwgo-30x20_4R {    width: 30px; height: 20px;    background: url('css_sprites.png') -1010px -1150px;}
.itwgo-30x20_4U {    width: 30px; height: 20px;    background: url('css_sprites.png') -1060px -1150px;}
.itwgo-30x20_5H {    width: 30px; height: 20px;    background: url('css_sprites.png') -1110px -1150px;}
.itwgo-30x20_5N {    width: 30px; height: 20px;    background: url('css_sprites.png') -1160px -1150px;}
.itwgo-30x20_5Q {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -10px;}
.itwgo-30x20_5U {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -50px;}
.itwgo-30x20_5W {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -90px;}
.itwgo-30x20_6C {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -130px;}
.itwgo-30x20_6E {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -170px;}
.itwgo-30x20_6H {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -210px;}
.itwgo-30x20_7E {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -250px;}
.itwgo-30x20_7F {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -290px;}
.itwgo-30x20_7I {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -330px;}
.itwgo-30x20_7J {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -370px;}
.itwgo-30x20_7M {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -410px;}
.itwgo-30x20_7R {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -450px;}
.itwgo-30x20_7V {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -490px;}
.itwgo-30x20_7W {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -530px;}
.itwgo-30x20_8I {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -570px;}
.itwgo-30x20_8M {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -610px;}
.itwgo-30x20_8Q {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -650px;}
.itwgo-30x20_8R {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -690px;}
.itwgo-30x20_8U {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -730px;}
.itwgo-30x20_9H {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -770px;}
.itwgo-30x20_9K {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -810px;}
.itwgo-30x20_9N {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -850px;}
.itwgo-30x20_9U {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -890px;}
.itwgo-30x20_9w {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -930px;}
.itwgo-30x20_A3 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -970px;}
.itwgo-30x20_A4 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -1010px;}
.itwgo-30x20_A5 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -1050px;}
.itwgo-30x20_A9 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -1090px;}
.itwgo-30x20_AA {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -1130px;}
.itwgo-30x20_AB {    width: 30px; height: 20px;    background: url('css_sprites.png') -10px -1190px;}
.itwgo-30x20_AE {    width: 30px; height: 20px;    background: url('css_sprites.png') -60px -1190px;}
.itwgo-30x20_AF {    width: 30px; height: 20px;    background: url('css_sprites.png') -110px -1190px;}
.itwgo-30x20_AI {    width: 30px; height: 20px;    background: url('css_sprites.png') -160px -1190px;}
.itwgo-30x20_AK {    width: 30px; height: 20px;    background: url('css_sprites.png') -210px -1190px;}
.itwgo-30x20_AM {    width: 30px; height: 20px;    background: url('css_sprites.png') -260px -1190px;}
.itwgo-30x20_AR {    width: 30px; height: 20px;    background: url('css_sprites.png') -310px -1190px;}
.itwgo-30x20_AS {    width: 30px; height: 20px;    background: url('css_sprites.png') -360px -1190px;}
.itwgo-30x20_AT {    width: 30px; height: 20px;    background: url('css_sprites.png') -410px -1190px;}
.itwgo-30x20_AV {    width: 30px; height: 20px;    background: url('css_sprites.png') -460px -1190px;}
.itwgo-30x20_AW {    width: 30px; height: 20px;    background: url('css_sprites.png') -510px -1190px;}
.itwgo-30x20_AY {    width: 30px; height: 20px;    background: url('css_sprites.png') -560px -1190px;}
.itwgo-30x20_AZ {    width: 30px; height: 20px;    background: url('css_sprites.png') -610px -1190px;}
.itwgo-30x20_B2 {    width: 30px; height: 20px;    background: url('css_sprites.png') -660px -1190px;}
.itwgo-30x20_F2 {    width: 30px; height: 20px;    background: url('css_sprites.png') -710px -1190px;}
.itwgo-30x20_F7 {    width: 30px; height: 20px;    background: url('css_sprites.png') -760px -1190px;}
.itwgo-30x20_FB {    width: 30px; height: 20px;    background: url('css_sprites.png') -810px -1190px;}
.itwgo-30x20_FD {    width: 30px; height: 20px;    background: url('css_sprites.png') -860px -1190px;}
.itwgo-30x20_FI {    width: 30px; height: 20px;    background: url('css_sprites.png') -910px -1190px;}
.itwgo-30x20_FN {    width: 30px; height: 20px;    background: url('css_sprites.png') -960px -1190px;}
.itwgo-30x20_FR {    width: 30px; height: 20px;    background: url('css_sprites.png') -1010px -1190px;}
.itwgo-30x20_FS {    width: 30px; height: 20px;    background: url('css_sprites.png') -1060px -1190px;}
.itwgo-30x20_FY {    width: 30px; height: 20px;    background: url('css_sprites.png') -1110px -1190px;}
.itwgo-30x20_FZ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1160px -1190px;}
.itwgo-30x20_G3 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -1190px;}
.itwgo-30x20_G8 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -10px;}
.itwgo-30x20_G9 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -50px;}
.itwgo-30x20_GF {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -90px;}
.itwgo-30x20_GR {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -130px;}
.itwgo-30x20_H1 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -170px;}
.itwgo-30x20_H2 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -210px;}
.itwgo-30x20_HF {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -250px;}
.itwgo-30x20_HO {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -290px;}
.itwgo-30x20_HV {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -330px;}
.itwgo-30x20_HZ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -370px;}
.itwgo-30x20_I5 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -410px;}
.itwgo-30x20_I7 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -450px;}
.itwgo-30x20_IB {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -490px;}
.itwgo-30x20_IC {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -530px;}
.itwgo-30x20_IE {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -570px;}
.itwgo-30x20_IG {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -610px;}
.itwgo-30x20_IT {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -650px;}
.itwgo-30x20_IX {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -690px;}
.itwgo-30x20_IZ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -730px;}
.itwgo-30x20_J2 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -770px;}
.itwgo-30x20_J3 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -810px;}
.itwgo-30x20_J5 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -850px;}
.itwgo-30x20_J8 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -890px;}
.itwgo-30x20_JA {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -930px;}
.itwgo-30x20_JE {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -970px;}
.itwgo-30x20_JF {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -1010px;}
.itwgo-30x20_JQ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -1050px;}
.itwgo-30x20_JU {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -1090px;}
.itwgo-30x20_K2 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -1130px;}
.itwgo-30x20_K5 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -1170px;}
.itwgo-30x20_K6 {    width: 30px; height: 20px;    background: url('css_sprites.png') -10px -1230px;}
.itwgo-30x20_KA {    width: 30px; height: 20px;    background: url('css_sprites.png') -60px -1230px;}
.itwgo-30x20_KK {    width: 30px; height: 20px;    background: url('css_sprites.png') -110px -1230px;}
.itwgo-30x20_KL {    width: 30px; height: 20px;    background: url('css_sprites.png') -160px -1230px;}
.itwgo-30x20_KM {    width: 30px; height: 20px;    background: url('css_sprites.png') -210px -1230px;}
.itwgo-30x20_KR {    width: 30px; height: 20px;    background: url('css_sprites.png') -260px -1230px;}
.itwgo-30x20_KU {    width: 30px; height: 20px;    background: url('css_sprites.png') -310px -1230px;}
.itwgo-30x20_KX {    width: 30px; height: 20px;    background: url('css_sprites.png') -360px -1230px;}
.itwgo-30x20_LB {    width: 30px; height: 20px;    background: url('css_sprites.png') -410px -1230px;}
.itwgo-30x20_LC {    width: 30px; height: 20px;    background: url('css_sprites.png') -460px -1230px;}
.itwgo-30x20_LG {    width: 30px; height: 20px;    background: url('css_sprites.png') -510px -1230px;}
.itwgo-30x20_LH {    width: 30px; height: 20px;    background: url('css_sprites.png') -560px -1230px;}
.itwgo-30x20_LI {    width: 30px; height: 20px;    background: url('css_sprites.png') -610px -1230px;}
.itwgo-30x20_LO {    width: 30px; height: 20px;    background: url('css_sprites.png') -660px -1230px;}
.itwgo-30x20_LP {    width: 30px; height: 20px;    background: url('css_sprites.png') -710px -1230px;}
.itwgo-30x20_LR {    width: 30px; height: 20px;    background: url('css_sprites.png') -760px -1230px;}
.itwgo-30x20_LS {    width: 30px; height: 20px;    background: url('css_sprites.png') -810px -1230px;}
.itwgo-30x20_LT {    width: 30px; height: 20px;    background: url('css_sprites.png') -860px -1230px;}
.itwgo-30x20_LX {    width: 30px; height: 20px;    background: url('css_sprites.png') -910px -1230px;}
.itwgo-30x20_M9 {    width: 30px; height: 20px;    background: url('css_sprites.png') -960px -1230px;}
.itwgo-30x20_ME {    width: 30px; height: 20px;    background: url('css_sprites.png') -1010px -1230px;}
.itwgo-30x20_MH {    width: 30px; height: 20px;    background: url('css_sprites.png') -1060px -1230px;}
.itwgo-30x20_MI {    width: 30px; height: 20px;    background: url('css_sprites.png') -1110px -1230px;}
.itwgo-30x20_MN {    width: 30px; height: 20px;    background: url('css_sprites.png') -1160px -1230px;}
.itwgo-30x20_MR {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -1230px;}
.itwgo-30x20_MW {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -1230px;}
.itwgo-30x20_MY {    width: 30px; height: 20px;    background: url('css_sprites.png') -10px -1270px;}
.itwgo-30x20_NF {    width: 30px; height: 20px;    background: url('css_sprites.png') -60px -1270px;}
.itwgo-30x20_NK {    width: 30px; height: 20px;    background: url('css_sprites.png') -110px -1270px;}
.itwgo-30x20_NT {    width: 30px; height: 20px;    background: url('css_sprites.png') -160px -1270px;}
.itwgo-30x20_NU {    width: 30px; height: 20px;    background: url('css_sprites.png') -210px -1270px;}
.itwgo-30x20_O6 {    width: 30px; height: 20px;    background: url('css_sprites.png') -260px -1270px;}
.itwgo-30x20_OA {    width: 30px; height: 20px;    background: url('css_sprites.png') -310px -1270px;}
.itwgo-30x20_OB {    width: 30px; height: 20px;    background: url('css_sprites.png') -360px -1270px;}
.itwgo-30x20_OM {    width: 30px; height: 20px;    background: url('css_sprites.png') -410px -1270px;}
.itwgo-30x20_OP {    width: 30px; height: 20px;    background: url('css_sprites.png') -460px -1270px;}
.itwgo-30x20_OR {    width: 30px; height: 20px;    background: url('css_sprites.png') -510px -1270px;}
.itwgo-30x20_OS {    width: 30px; height: 20px;    background: url('css_sprites.png') -560px -1270px;}
.itwgo-30x20_OU {    width: 30px; height: 20px;    background: url('css_sprites.png') -610px -1270px;}
.itwgo-30x20_OV {    width: 30px; height: 20px;    background: url('css_sprites.png') -660px -1270px;}
.itwgo-30x20_P0 {    width: 30px; height: 20px;    background: url('css_sprites.png') -710px -1270px;}
.itwgo-30x20_P6 {    width: 30px; height: 20px;    background: url('css_sprites.png') -760px -1270px;}
.itwgo-30x20_P9 {    width: 30px; height: 20px;    background: url('css_sprites.png') -810px -1270px;}
.itwgo-30x20_PD {    width: 30px; height: 20px;    background: url('css_sprites.png') -860px -1270px;}
.itwgo-30x20_PG {    width: 30px; height: 20px;    background: url('css_sprites.png') -910px -1270px;}
.itwgo-30x20_PR {    width: 30px; height: 20px;    background: url('css_sprites.png') -960px -1270px;}
.itwgo-30x20_PX {    width: 30px; height: 20px;    background: url('css_sprites.png') -1010px -1270px;}
.itwgo-30x20_PY {    width: 30px; height: 20px;    background: url('css_sprites.png') -1060px -1270px;}
.itwgo-30x20_PZ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1110px -1270px;}
.itwgo-30x20_Q9 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1160px -1270px;}
.itwgo-30x20_QC {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -1270px;}
.itwgo-30x20_QF {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -1270px;}
.itwgo-30x20_QH {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -10px;}
.itwgo-30x20_QR {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -50px;}
.itwgo-30x20_QS {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -90px;}
.itwgo-30x20_QV {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -130px;}
.itwgo-30x20_QZ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -170px;}
.itwgo-30x20_R2 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -210px;}
.itwgo-30x20_R3 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -250px;}
.itwgo-30x20_RA {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -290px;}
.itwgo-30x20_RO {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -330px;}
.itwgo-30x20_RQ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -370px;}
.itwgo-30x20_RX {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -410px;}
.itwgo-30x20_s2 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -450px;}
.itwgo-30x20_S3 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -490px;}
.itwgo-30x20_S4 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -530px;}
.itwgo-30x20_S7 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -570px;}
.itwgo-30x20_SB {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -610px;}
.itwgo-30x20_SC {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -650px;}
.itwgo-30x20_SG {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -690px;}
.itwgo-30x20_SI {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -730px;}
.itwgo-30x20_SN {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -770px;}
.itwgo-30x20_SP {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -810px;}
.itwgo-30x20_SQ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -850px;}
.itwgo-30x20_SS {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -890px;}
.itwgo-30x20_ST {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -930px;}
.itwgo-30x20_SU {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -970px;}
.itwgo-30x20_SV {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -1010px;}
.itwgo-30x20_SW {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -1050px;}
.itwgo-30x20_SX {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -1090px;}
.itwgo-30x20_SY {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -1130px;}
.itwgo-30x20_SZ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -1170px;}
.itwgo-30x20_T5 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -1210px;}
.itwgo-30x20_TA {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -1250px;}
.itwgo-30x20_TB {    width: 30px; height: 20px;    background: url('css_sprites.png') -10px -1310px;}
.itwgo-30x20_TC {    width: 30px; height: 20px;    background: url('css_sprites.png') -60px -1310px;}
.itwgo-30x20_TF {    width: 30px; height: 20px;    background: url('css_sprites.png') -110px -1310px;}
.itwgo-30x20_TG {    width: 30px; height: 20px;    background: url('css_sprites.png') -160px -1310px;}
.itwgo-30x20_TJ {    width: 30px; height: 20px;    background: url('css_sprites.png') -210px -1310px;}
.itwgo-30x20_TK {    width: 30px; height: 20px;    background: url('css_sprites.png') -260px -1310px;}
.itwgo-30x20_TM {    width: 30px; height: 20px;    background: url('css_sprites.png') -310px -1310px;}
.itwgo-30x20_TN {    width: 30px; height: 20px;    background: url('css_sprites.png') -360px -1310px;}
.itwgo-30x20_TR {    width: 30px; height: 20px;    background: url('css_sprites.png') -410px -1310px;}
.itwgo-30x20_TT {    width: 30px; height: 20px;    background: url('css_sprites.png') -460px -1310px;}
.itwgo-30x20_TU {    width: 30px; height: 20px;    background: url('css_sprites.png') -510px -1310px;}
.itwgo-30x20_TZ {    width: 30px; height: 20px;    background: url('css_sprites.png') -560px -1310px;}
.itwgo-30x20_U2 {    width: 30px; height: 20px;    background: url('css_sprites.png') -610px -1310px;}
.itwgo-30x20_U6 {    width: 30px; height: 20px;    background: url('css_sprites.png') -660px -1310px;}
.itwgo-30x20_UA {    width: 30px; height: 20px;    background: url('css_sprites.png') -710px -1310px;}
.itwgo-30x20_UI {    width: 30px; height: 20px;    background: url('css_sprites.png') -760px -1310px;}
.itwgo-30x20_UK {    width: 30px; height: 20px;    background: url('css_sprites.png') -810px -1310px;}
.itwgo-30x20_UL {    width: 30px; height: 20px;    background: url('css_sprites.png') -860px -1310px;}
.itwgo-30x20_UM {    width: 30px; height: 20px;    background: url('css_sprites.png') -910px -1310px;}
.itwgo-30x20_UN {    width: 30px; height: 20px;    background: url('css_sprites.png') -960px -1310px;}
.itwgo-30x20_UO {    width: 30px; height: 20px;    background: url('css_sprites.png') -1010px -1310px;}
.itwgo-30x20_UP {    width: 30px; height: 20px;    background: url('css_sprites.png') -1060px -1310px;}
.itwgo-30x20_UT {    width: 30px; height: 20px;    background: url('css_sprites.png') -1110px -1310px;}
.itwgo-30x20_V0 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1160px -1310px;}
.itwgo-30x20_V2 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1210px -1310px;}
.itwgo-30x20_V3 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1260px -1310px;}
.itwgo-30x20_V4 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1310px -1310px;}
.itwgo-30x20_V7 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -10px;}
.itwgo-30x20_VA {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -50px;}
.itwgo-30x20_VJ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -90px;}
.itwgo-30x20_VL {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -130px;}
.itwgo-30x20_VR {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -170px;}
.itwgo-30x20_VS {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -210px;}
.itwgo-30x20_VW {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -250px;}
.itwgo-30x20_VX {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -290px;}
.itwgo-30x20_VY {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -330px;}
.itwgo-30x20_W3 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -370px;}
.itwgo-30x20_W4 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -410px;}
.itwgo-30x20_W5 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -450px;}
.itwgo-30x20_W9 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -490px;}
.itwgo-30x20_WM {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -530px;}
.itwgo-30x20_WS {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -570px;}
.itwgo-30x20_WV {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -610px;}
.itwgo-30x20_X3 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -650px;}
.itwgo-30x20_XC {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -690px;}
.itwgo-30x20_XG {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -730px;}
.itwgo-30x20_XJ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -770px;}
.itwgo-30x20_XL {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -810px;}
.itwgo-30x20_XQ {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -850px;}
.itwgo-30x20_xv {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -890px;}
.itwgo-30x20_Y4 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -930px;}
.itwgo-30x20_Y7 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -970px;}
.itwgo-30x20_YC {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -1010px;}
.itwgo-30x20_YK {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -1050px;}
.itwgo-30x20_YM {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -1090px;}
.itwgo-30x20_YV {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -1130px;}
.itwgo-30x20_Z2 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -1170px;}
.itwgo-30x20_Z5 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -1210px;}
.itwgo-30x20_Z6 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -1250px;}
.itwgo-30x20_Z7 {    width: 30px; height: 20px;    background: url('css_sprites.png') -1360px -1290px;}
.itwgo-30x20_ZA {    width: 30px; height: 20px;    background: url('css_sprites.png') -10px -1350px;}
.itwgo-30x20_ZG {    width: 30px; height: 20px;    background: url('css_sprites.png') -60px -1350px;}
.itwgo-30x20_ZH {    width: 30px; height: 20px;    background: url('css_sprites.png') -110px -1350px;}
.itwgo-30x20_ZO {    width: 30px; height: 20px;    background: url('css_sprites.png') -160px -1350px;}
.itwgo-30x20_ZS {    width: 30px; height: 20px;    background: url('css_sprites.png') -210px -1350px;}
.itwgo-30x20_QP {    width: 30px; height: 20px;    background: url('css_sprites.png') -210px -1350px;}

.itwgo-60x35_0B {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -10px;}
.itwgo-60x35_1X {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -10px;}
.itwgo-60x35_2I {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -65px;}
.itwgo-60x35_2J {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -65px;}
.itwgo-60x35_2K {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -10px;}
.itwgo-60x35_2L {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -65px;}
.itwgo-60x35_2M {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -120px;}
.itwgo-60x35_2N {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -120px;}
.itwgo-60x35_2T {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -120px;}
.itwgo-60x35_2Z {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -175px;}
.itwgo-60x35_3E {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -175px;}
.itwgo-60x35_3L {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -175px;}
.itwgo-60x35_3M {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -10px;}
.itwgo-60x35_3P {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -65px;}
.itwgo-60x35_3S {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -120px;}
.itwgo-60x35_3U {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -175px;}
.itwgo-60x35_4M {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -230px;}
.itwgo-60x35_4O {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -230px;}
.itwgo-60x35_4R {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -230px;}
.itwgo-60x35_4U {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -230px;}
.itwgo-60x35_5H {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -10px;}
.itwgo-60x35_5N {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -65px;}
.itwgo-60x35_5Q {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -120px;}
.itwgo-60x35_5U {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -175px;}
.itwgo-60x35_5W {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -230px;}
.itwgo-60x35_6C {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -285px;}
.itwgo-60x35_6E {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -285px;}
.itwgo-60x35_6H {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -285px;}
.itwgo-60x35_7E {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -285px;}
.itwgo-60x35_7F {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -285px;}
.itwgo-60x35_7I {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -340px;}
.itwgo-60x35_7J {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -340px;}
.itwgo-60x35_7M {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -340px;}
.itwgo-60x35_7R {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -340px;}
.itwgo-60x35_7V {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -340px;}
.itwgo-60x35_7W {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -10px;}
.itwgo-60x35_8I {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -65px;}
.itwgo-60x35_8M {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -120px;}
.itwgo-60x35_8Q {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -175px;}
.itwgo-60x35_8R {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -230px;}
.itwgo-60x35_8U {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -285px;}
.itwgo-60x35_9H {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -340px;}
.itwgo-60x35_9K {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -395px;}
.itwgo-60x35_9N {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -395px;}
.itwgo-60x35_9U {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -395px;}
.itwgo-60x35_9w {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -395px;}
.itwgo-60x35_A3 {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -395px;}
.itwgo-60x35_A4 {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -395px;}
.itwgo-60x35_A5 {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -10px;}
.itwgo-60x35_A9 {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -65px;}
.itwgo-60x35_AA {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -120px;}
.itwgo-60x35_AB {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -175px;}
.itwgo-60x35_AE {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -230px;}
.itwgo-60x35_AF {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -285px;}
.itwgo-60x35_AI {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -340px;}
.itwgo-60x35_AK {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -395px;}
.itwgo-60x35_AM {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -450px;}
.itwgo-60x35_AR {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -450px;}
.itwgo-60x35_AS {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -450px;}
.itwgo-60x35_AT {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -450px;}
.itwgo-60x35_AV {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -450px;}
.itwgo-60x35_AW {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -450px;}
.itwgo-60x35_AY {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -450px;}
.itwgo-60x35_AZ {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -505px;}
.itwgo-60x35_B2 {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -505px;}
.itwgo-60x35_B3 {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -505px;}
.itwgo-60x35_B5 {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -505px;}
.itwgo-60x35_B6 {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -505px;}
.itwgo-60x35_B7 {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -505px;}
.itwgo-60x35_BA {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -505px;}
.itwgo-60x35_BB {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -10px;}
.itwgo-60x35_BE {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -65px;}
.itwgo-60x35_BH {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -120px;}
.itwgo-60x35_BJ {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -175px;}
.itwgo-60x35_BP {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -230px;}
.itwgo-60x35_BT {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -285px;}
.itwgo-60x35_BV {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -340px;}
.itwgo-60x35_BW {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -395px;}
.itwgo-60x35_BX {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -450px;}
.itwgo-60x35_CO {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -505px;}
.itwgo-60x35_CU {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -560px;}
.itwgo-60x35_CX {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -560px;}
.itwgo-60x35_CY {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -560px;}
.itwgo-60x35_CZ {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -560px;}
.itwgo-60x35_D2 {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -560px;}
.itwgo-60x35_D6 {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -560px;}
.itwgo-60x35_D7 {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -560px;}
.itwgo-60x35_DC {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -560px;}
.itwgo-60x35_DE {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -10px;}
.itwgo-60x35_DG {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -65px;}
.itwgo-60x35_DL {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -120px;}
.itwgo-60x35_DN {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -175px;}
.itwgo-60x35_DV {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -230px;}
.itwgo-60x35_DX {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -285px;}
.itwgo-60x35_DY {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -340px;}
.itwgo-60x35_E7 {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -395px;}
.itwgo-60x35_EI {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -450px;}
.itwgo-60x35_EK {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -505px;}
.itwgo-60x35_EL {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -560px;}
.itwgo-60x35_EQ {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -615px;}
.itwgo-60x35_ET {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -615px;}
.itwgo-60x35_EY {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -615px;}
.itwgo-60x35_F2 {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -615px;}
.itwgo-60x35_F7 {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -615px;}
.itwgo-60x35_FB {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -615px;}
.itwgo-60x35_FD {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -615px;}
.itwgo-60x35_FI {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -615px;}
.itwgo-60x35_FN {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -615px;}
.itwgo-60x35_FR {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -670px;}
.itwgo-60x35_FS {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -670px;}
.itwgo-60x35_FY {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -670px;}
.itwgo-60x35_FZ {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -670px;}
.itwgo-60x35_G3 {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -670px;}
.itwgo-60x35_G8 {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -670px;}
.itwgo-60x35_G9 {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -670px;}
.itwgo-60x35_GF {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -670px;}
.itwgo-60x35_GR {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -670px;}
.itwgo-60x35_H1 {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -10px;}
.itwgo-60x35_H2 {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -65px;}
.itwgo-60x35_HF {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -120px;}
.itwgo-60x35_HO {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -175px;}
.itwgo-60x35_HV {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -230px;}
.itwgo-60x35_HZ {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -285px;}
.itwgo-60x35_I5 {    width: 60px; height: 35px;    background: url('css_sprites.png') -320px -1343px;}
.itwgo-60x35_I7 {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -395px;}
.itwgo-60x35_IB {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -450px;}
.itwgo-60x35_IC {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -505px;}
.itwgo-60x35_IE {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -560px;}
.itwgo-60x35_IG {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -615px;}
.itwgo-60x35_IT {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -670px;}
.itwgo-60x35_IX {    width: 60px; height: 35px;    background: url('css_sprites.png') -320px -1343px;}
.itwgo-60x35_IZ {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -725px;}
.itwgo-60x35_J2 {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -725px;}
.itwgo-60x35_J3 {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -725px;}
.itwgo-60x35_J5 {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -725px;}
.itwgo-60x35_J8 {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -725px;}
.itwgo-60x35_JA {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -725px;}
.itwgo-60x35_JE {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -725px;}
.itwgo-60x35_JF {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -725px;}
.itwgo-60x35_JQ {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -725px;}
.itwgo-60x35_JU {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -10px;}
.itwgo-60x35_K2 {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -65px;}
.itwgo-60x35_K5 {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -120px;}
.itwgo-60x35_K6 {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -175px;}
.itwgo-60x35_KA {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -230px;}
.itwgo-60x35_KK {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -285px;}
.itwgo-60x35_KL {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -340px;}
.itwgo-60x35_KM {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -395px;}
.itwgo-60x35_KR {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -450px;}
.itwgo-60x35_KU {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -505px;}
.itwgo-60x35_KX {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -560px;}
.itwgo-60x35_LB {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -615px;}
.itwgo-60x35_LC {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -670px;}
.itwgo-60x35_LG {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -725px;}
.itwgo-60x35_LH {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -780px;}
.itwgo-60x35_LI {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -780px;}
.itwgo-60x35_LO {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -780px;}
.itwgo-60x35_LP {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -780px;}
.itwgo-60x35_LR {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -780px;}
.itwgo-60x35_LS {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -780px;}
.itwgo-60x35_LT {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -780px;}
.itwgo-60x35_LX {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -780px;}
.itwgo-60x35_M9 {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -780px;}
.itwgo-60x35_ME {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -780px;}
.itwgo-60x35_MH {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -780px;}
.itwgo-60x35_MI {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -835px;}
.itwgo-60x35_MN {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -835px;}
.itwgo-60x35_MR {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -835px;}
.itwgo-60x35_MW {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -835px;}
.itwgo-60x35_MY {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -835px;}
.itwgo-60x35_NF {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -835px;}
.itwgo-60x35_NK {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -835px;}
.itwgo-60x35_NT {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -835px;}
.itwgo-60x35_NU {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -835px;}
.itwgo-60x35_O6 {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -835px;}
.itwgo-60x35_OA {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -835px;}
.itwgo-60x35_OB {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -10px;}
.itwgo-60x35_OM {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -65px;}
.itwgo-60x35_OP {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -120px;}
.itwgo-60x35_OR {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -175px;}
.itwgo-60x35_OS {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -230px;}
.itwgo-60x35_OU {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -285px;}
.itwgo-60x35_OV {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -340px;}
.itwgo-60x35_P0 {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -395px;}
.itwgo-60x35_P6 {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -450px;}
.itwgo-60x35_P9 {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -505px;}
.itwgo-60x35_PD {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -560px;}
.itwgo-60x35_PG {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -615px;}
.itwgo-60x35_PR {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -670px;}
.itwgo-60x35_PX {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -725px;}
.itwgo-60x35_PY {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -780px;}
.itwgo-60x35_PZ {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -835px;}
.itwgo-60x35_Q9 {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -890px;}
.itwgo-60x35_QC {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -890px;}
.itwgo-60x35_QF {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -890px;}
.itwgo-60x35_QH {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -890px;}
.itwgo-60x35_QR {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -890px;}
.itwgo-60x35_QS {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -890px;}
.itwgo-60x35_QV {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -890px;}
.itwgo-60x35_QZ {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -890px;}
.itwgo-60x35_R2 {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -890px;}
.itwgo-60x35_R3 {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -890px;}
.itwgo-60x35_RA {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -890px;}
.itwgo-60x35_RO {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -890px;}
.itwgo-60x35_RQ {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -10px;}
.itwgo-60x35_RX {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -65px;}
.itwgo-60x35_s2 {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -120px;}
.itwgo-60x35_S3 {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -175px;}
.itwgo-60x35_S4 {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -230px;}
.itwgo-60x35_S7 {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -285px;}
.itwgo-60x35_SB {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -340px;}
.itwgo-60x35_SC {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -395px;}
.itwgo-60x35_SG {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -450px;}
.itwgo-60x35_SI {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -505px;}
.itwgo-60x35_SN {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -560px;}
.itwgo-60x35_SP {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -615px;}
.itwgo-60x35_SQ {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -670px;}
.itwgo-60x35_SS {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -725px;}
.itwgo-60x35_ST {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -780px;}
.itwgo-60x35_SU {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -835px;}
.itwgo-60x35_SV {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -890px;}
.itwgo-60x35_SW {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -945px;}
.itwgo-60x35_SX {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -945px;}
.itwgo-60x35_SY {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -945px;}
.itwgo-60x35_SZ {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -945px;}
.itwgo-60x35_T5 {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -945px;}
.itwgo-60x35_TA {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -945px;}
.itwgo-60x35_TB {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -945px;}
.itwgo-60x35_TC {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -945px;}
.itwgo-60x35_TF {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -945px;}
.itwgo-60x35_TG {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -945px;}
.itwgo-60x35_TJ {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -945px;}
.itwgo-60x35_TK {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -945px;}
.itwgo-60x35_TM {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -945px;}
.itwgo-60x35_TN {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -10px;}
.itwgo-60x35_TR {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -65px;}
.itwgo-60x35_TT {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -120px;}
.itwgo-60x35_TU {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -175px;}
.itwgo-60x35_TZ {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -230px;}
.itwgo-60x35_U2 {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -285px;}
.itwgo-60x35_U6 {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -340px;}
.itwgo-60x35_UA {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -395px;}
.itwgo-60x35_UI {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -450px;}
.itwgo-60x35_UK {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -505px;}
.itwgo-60x35_UL {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -560px;}
.itwgo-60x35_UM {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -615px;}
.itwgo-60x35_UN {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -670px;}
.itwgo-60x35_UO {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -725px;}
.itwgo-60x35_UP {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -780px;}
.itwgo-60x35_UT {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -835px;}
.itwgo-60x35_V0 {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -890px;}
.itwgo-60x35_V2 {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -945px;}
.itwgo-60x35_V3 {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -1000px;}
.itwgo-60x35_V4 {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -1000px;}
.itwgo-60x35_V7 {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -1000px;}
.itwgo-60x35_VA {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -1000px;}
.itwgo-60x35_VJ {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -1000px;}
.itwgo-60x35_VL {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -1000px;}
.itwgo-60x35_VR {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -1000px;}
.itwgo-60x35_VS {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -1000px;}
.itwgo-60x35_VW {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -1000px;}
.itwgo-60x35_VX {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -1000px;}
.itwgo-60x35_VY {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -1000px;}
.itwgo-60x35_W3 {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -1000px;}
.itwgo-60x35_W4 {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -1000px;}
.itwgo-60x35_W5 {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -1000px;}
.itwgo-60x35_W9 {    width: 60px; height: 35px;    background: url('css_sprites.png') -10px -1055px;}
.itwgo-60x35_WM {    width: 60px; height: 35px;    background: url('css_sprites.png') -90px -1055px;}
.itwgo-60x35_WS {    width: 60px; height: 35px;    background: url('css_sprites.png') -170px -1055px;}
.itwgo-60x35_WV {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -1055px;}
.itwgo-60x35_X3 {    width: 60px; height: 35px;    background: url('css_sprites.png') -330px -1055px;}
.itwgo-60x35_XC {    width: 60px; height: 35px;    background: url('css_sprites.png') -410px -1055px;}
.itwgo-60x35_XG {    width: 60px; height: 35px;    background: url('css_sprites.png') -490px -1055px;}
.itwgo-60x35_XJ {    width: 60px; height: 35px;    background: url('css_sprites.png') -570px -1055px;}
.itwgo-60x35_XL {    width: 60px; height: 35px;    background: url('css_sprites.png') -650px -1055px;}
.itwgo-60x35_XQ {    width: 60px; height: 35px;    background: url('css_sprites.png') -730px -1055px;}
.itwgo-60x35_xv {    width: 60px; height: 35px;    background: url('css_sprites.png') -810px -1055px;}
.itwgo-60x35_Y4 {    width: 60px; height: 35px;    background: url('css_sprites.png') -890px -1055px;}
.itwgo-60x35_Y7 {    width: 60px; height: 35px;    background: url('css_sprites.png') -970px -1055px;}
.itwgo-60x35_YC {    width: 60px; height: 35px;    background: url('css_sprites.png') -1050px -1055px;}
.itwgo-60x35_YK {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -10px;}
.itwgo-60x35_YM {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -65px;}
.itwgo-60x35_YV {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -120px;}
.itwgo-60x35_Z2 {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -175px;}
.itwgo-60x35_Z5 {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -230px;}
.itwgo-60x35_Z6 {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -285px;}
.itwgo-60x35_Z7 {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -340px;}
.itwgo-60x35_ZA {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -395px;}
.itwgo-60x35_ZG {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -450px;}
.itwgo-60x35_ZH {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -505px;}
.itwgo-60x35_ZO {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -560px;}
.itwgo-60x35_ZS {    width: 60px; height: 35px;    background: url('css_sprites.png') -1130px -615px;}
.itwgo-60x35_QP {    width: 60px; height: 35px;    background: url('css_sprites.png') -250px -1343px;}
.icon-flight-box {
    background:transparent;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
    cursor: pointer;
}
.Slider-deales {
    /* margin: 20px 0; */
    margin-top: 10px;
}
.ico-flight{
    width: 35px;
    padding: 10px;
}
.slider-box{
    background: #fff;
    padding: 20px;
    border-radius: 5px;
}
.cardSlider{
    margin: 10px 25px 0px 0px!important;
    border-radius: 10px;
    box-shadow: -1px 3px 9px -3px #ccc !important;
    position: relative;
}
.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 21px;
    height: 20px;
    padding: 0;
    transform: translate(0, -55%);
    cursor: pointer;
    color: #1675b8;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 34px;
    line-height: 1;
    opacity: 1;
    color: #1675b8;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-next {
    right: -55px;
}
.slick-prev {
    left: -55px;
}


.singleimgbox-1 {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 10px;
}
.singleimgbox-1 img {
    width: 100%;
    object-fit: cover;
    height: 250px;

}
.selectactivity-container .hotelrow {
    margin:  0;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
}

.breadcrumb-custom.margin {
    margin-top: 40px;
}
/* .colspace {
    padding: 10px!important;
} */

.freeCancRow{
    padding: 0px 15px;
}
.colspacemini {
    padding: 0px;
}
.multiimgbox  {
    width: 100%;
}
.multiimgbox img {
    width: 100%;
    height: 160px;
    border-radius: 0px;
    margin-bottom: 15px;
}
.sideimgboxleft {
    width: 100%;
    height: 100%;
}
.sideimgboxright {
    width: 100%;
    height: 100%;
    position: relative;
}

.sideimgboxleft img {
    height: 80px;
}
.sideimgboxright img {
    height: 80px;
}

a.linkviewhotel {
    position: absolute;
    text-align: center;
    top: 50px;
    left: 40px;
    z-index: 9;
    font-size: 16px;
    font-weight: 500;
}
.sideimgboxleft img {
    width: 100%;
    border-radius: 0px;
}
.sideimgboxright img {
    width: 100%;
    border-radius: 0px;
}
.ratedetailcard {
    width: 95%;
    /* height: 45%; */
    padding: 10px;
    margin-left: 13px;
    border-radius: 23px;
    border: 1px solid #808080;
    /* border-bottom: 0px; */
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
}
.tltcarddts {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
}

.boxprst {
    display: flex;
    padding: 0px;
    width: 100%;
}
.chargedts {
    flex-basis: 70%;
    text-align: left;
}
.chargeprice {
    flex-basis: 30%;
    text-align: right;
}
.item-rateoff {
    font-size: 14px;
    font-weight: 500;
    text-decoration: line-through;
    color: #999;
}
.item-rateopen {
    font-size: 17px;
    font-weight: 500;
}
.item-frt {
    font-size: 14px;
    font-weight: 500;
    color: #5d5a5a;
}
.appliedbox {
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    color: green;
}
.nonrefundico {
    font-size: 14px;
    font-weight: 500;
}
.nonrefundico svg {
    color: red;
}
.nonrefundico span {
    margin-left: 8px;
}

.nonrefundicochecked {
    font-size: 14px;
    font-weight: 500;
}
.nonrefundicochecked svg {
    color:blue;
}
.nonrefundicochecked span {
    margin-left: 8px;
}
.flexdiv {
    display: flex;
    align-items: center;
    width: 107%;
    padding: 10px;
    border: 1px solid #808080;
    margin: -11px;
    margin-top: 22%;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
}
.tltbooknow button {
    font-size: 12px;
    border-radius: 30px;
}
.tltdiv {
    font-size: 14px;
    font-weight: 500;
    flex-basis: 50%;
}
.tltbooknow {
    flex-basis: 50%;
    text-align: end;
}
.tlthotelwithstr {
    display: flex;
    width: 100%;
    align-items: center;
}
.tltstr {
    margin-left: 20px;
}
.tltstr ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.tltstr ul li {
    margin-right: 8px;
}
.tlthtlstr {
    font-size: 20px;
    font-weight: 500;
    color: #0b78bc;
}
.hoteladdress p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}
.tabsrow {
    margin: 0px 5px 20px !important;
    padding: 15px 0px;
    background: #fff;
    border-radius: 10px;
}
button.hoteltab {
    padding: 0;
    flex-grow: 0;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
}
.tabboxhotel {
    padding: 20px;
}
.desc-tlt {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 6px;
}
ul.tabullist {
    list-style-position: initial;
    padding-left: 20px;
}
ul.tabullist li {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8;
}
.paratabtext p {
    font-size: 15px;
    font-weight: 500;
}
.coltabspace {
    padding: 0!important;
}
.roombox {
    padding: 15px;
}
.roomdtlstlt {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}
.miniico-roomdtls {
    font-size: 13px;
    font-weight: 500;
    color: #565656;
    position: relative;
    padding-left: 25px;
}
.miniico-roomdtls svg {
    position: absolute;
    top: 1px;
    left: 0;
}
.cancellationboxhotel {
    padding: 15px 0;
}
.ltlmode {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px;
}
.ltlmode svg {
    color: #3BB54A;
    font-size: 22px;
}

.free_cancel {
    font-size: 14px;
    font-weight: 500;
    /* margin-bottom: 10px; */
}
.free_cancel svg {
    color: #3BB54A;
    font-size: 20px;
}
.free_cancel span {
    margin-left: 8px;
}

.ltlmode span {
    padding-left: 4px;
}
span.datesetsw {
    font-size: 14px;
}
button.vewclsbtn {
    font-size: 13px;
    text-transform: lowercase;
    /* margin: 11px 3px; */
}
.msgprovider {
    display: inline-block;
    padding: 5px;
    margin-top: 10px;
    background: #3BB54A;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    cursor: context-menu;
}
.roomrent {
    padding: 15px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
}
.btnbookhotel {
    padding: 15px;
}
.btnbookhotel button {
    width: 100%;
    font-size: 15px;
    padding: 5px;
}
.roomdetailsbookingbox {
    border-radius: 8px;
    box-shadow: 0px 1px 7px -1px rgb(0 0 0 / 30%);
    margin-left: 5px!important;
    margin-right: 5px!important;
    margin-bottom: 8px!important;
}
.mainaddonbox {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.bosaddonfilter {
    padding: 10px 15px;
    margin-right: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 7px -1px rgb(0 0 0 / 30%);
}

label.labelcontroladdoncheck {
    width: 100%;
    margin: 0;
    /* margin-bottom: 10px; */
}
label.labelcontroladdoncheck span {
    padding: 0;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.pointercls{
    cursor: pointer;
}

li.cancel-policy{
    margin-bottom: 0px;
    font-size: 14px;
}

.activity-tab .MuiTab-root {
    min-width: 25% !important;
    padding: 0px 15px;
    font-weight: 600;
    background: #1676b808;
    font-size: 16px;
    border: 1px solid #1676b814;
}

.activity-tab svg.MuiSvgIcon-root {
    font-size: 16px !important;
}

.refreshmentIcon {
    font-size: 14px !important;
    margin-bottom: 2px;
    margin-right: 4px;
}
.inclution-list{
    list-style: none;
    font-size: 12px;
    padding: 0px;
    padding-right: 15px;
    border-right: 1px dotted #ddd;
}
.inclution-list svg{
    color: #fff;
    font-size: 13px !important;
    background: #3aa014;
    margin-top: 4px;
    margin-right: 11px;
}


.inclution-list-simple{
    font-size: 12px;
    padding: 0px;
    margin: 0;
}

.exclution-list{
    list-style: none;
    font-size: 12px;
    padding: 0px;
}

.exclution-list svg{
    color: #fff;
    font-size: 13px !important;
    background: #EB2026;
    margin-top: 4px;
    margin-right: 11px;
}

.inclution-list li, .exclution-list li, .inclution-list-simple li{
    margin-bottom: 6px;
    font-size: 14px;
    display: flex;
}
label.filtersroompage span {
    padding: 0;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.bosaddonfilter {
    padding: 10px 15px;
    margin-right: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 7px -1px rgb(0 0 0 / 30%);
}

label.filtersroompage {
    width: 100%;
    margin: 0;
    margin-bottom: 0px;
}



.act-txt-xs{
    font-size: 12px;
    font-weight: 500;
}

.act-search-input{
    width: 100%;
}

.act-heading-1 {
    font-size: 22px;
    font-weight: 700;
    padding: 20px 0px 10px;
}

.activity-container .flitercolumstick {
    position: initial !important;
    top: 0;
}

.actfare-wrapper{
    padding: 10px 30px;
}

.actfare-ttl-1{
    color: #FF0000;
    font-size: 10px;
    margin-bottom: 0;
}

.actfare-ttl-2{
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
}

.actfare-ttl-3{
    font-size: 10px;
    font-weight: 600;
}

.actfare-ttl-11{
    color: #1676b8;
    font-weight: 700;
    margin-bottom: 0;
}

.actfare-ttl-light{
    color: #9e9e9e;
    font-size: 10px;
    margin-bottom: 0;
}

.actfare-ttl-bold{
    margin-top: 10px;
    font-size: 13px;
    color: #000;
    font-weight: 600;
    margin: 0;
}

.act-select-wrapper .hotelrow{
    padding: 15px;
    margin-bottom: 15px;
}

.act-select-wrapper .hoteldtlstag{
    padding: 5px;
}

.act-select-wrapper .linkviewhotel{
    font-size: 14px;
}

.package-wrapper{
    padding: 15px;
}
.package-wrapper .btn-border{
    border-radius: 4px !important;
    background: #fff !important;
    color: #1676b8 !important;
    border: 1px solid #1676b8;
    font-size: 12px;
    font-weight: 600;
}

.package-wrapper .btn-border-radius{
    border-radius: 4px !important;
    background: #1676b8 !important;
    color: #fff !important;
    border: 1px solid #1676b8;
    font-size: 12px;
    font-weight: 600;

}

.btn-disabled-border-radius{
    border-radius: 4px !important;
    background: #fff !important;
    color: #1676b8 !important;
    font-size: 12px !important;
    font-weight: 600 !important;

}
.btn-selected-border-radius{
    border-radius: 4px !important;
    background: #4caf50 !important;
    color: #ffffff !important;
    border: 1px solid #4caf50 !important;
    font-size: 12px !important;
    font-weight: 600 !important;

}
.package-ttl-1 {
    display: flow-root;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
}
.package-heading{
    display: flow-root;
    padding-bottom: 15px;
    border-bottom: 1px solid;
    color: #000;
    font-weight: 600;
}
.package-price{
    display: flow-root;
    padding-bottom: 15px;
    color: #000;
    font-weight: 600;
}
.pull-right{
    float: right;
}
.pull-left{
    float: left;
}

.package-ul{
    padding: 0px 5px;
    /* border-right: 1px solid #ccc; */
}

.txt-xs{
    font-size: 11px;
    font-weight: 600;
}


.person-btn-grp button{
    color: #000 !important;
    min-width: 30px !important;
}

/* activity booking css */

.actbooking-img-1 img{
    width: 100%;
}
.xs-txt-p{
    font-size: 13px;
    font-weight: 600;
    color: #1676b8;
    margin-top: 10px;
    margin-bottom: 0px;
}

.info-box {
    border-right: 1px solid #808080;
}

.booking-package-ul {
    padding: 0px 15px;
}

.abstlt {
    display: inline-block;
    padding: 10px 15px;
    background: #0b78bc;
    color: #fff;
    margin-left: -16px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.tlt-input-traveller{
    margin-bottom: 5px !important;
}

.calcBox {
    margin-top: 15px;
    border-radius: 9px;
    padding-top: 10px;
}

.tlt {
    font-size: 18px;
    font-weight: 700;
}

.backtosearch {
    display: flex;
    align-items: center;
}

.fare-tlt {
    font-size: 18px;
    font-weight: 500;
    flex-basis: 67%;
}

button.setbtnback {
    font-size: 13px;
    font-weight: 500;
    padding: 8px !important;
    text-transform: capitalize!important;
}

.selectactivity-container{
    margin-top: 15px;
    margin-bottom: 30px;
}

.reviewbtncontent {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    /* box-shadow: 0 1px 5px -1px rgb(0 0 0 / 40%); */
    text-align: right;
}

.singleimgbox img {
    width: 100%;
    object-fit: cover;
    border-radius: 0px;
    height: 290px;
}

table.hotelcancellationtbl {
    width: 98%;
    text-align: center;
    margin: 10px auto 0;
}

table tr.firstrowtbl td {
    /* background: #6e9abf; */
    color: #000 !important;
    border: 0;
}

table.hotelcancellationtbl tr td {
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: grey;
}

.tlttravellertype {
    font-size: 13px;
    font-weight: 400;
    color: grey;
    flex-basis: 100%;
}


.fareBox .tlt{
    font-weight: 600 !important;
}

.itinenary-list p{
    font-size: 12px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
}

.itinenary-list p li{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    text-transform: none;
}
.activity-details .MuiTabs-indicator{
    background-color: #1676b8;
}

.activity-details .MuiButtonBase-root.Mui-selected {
    color: #fff !important;
    border: 1px;
    background: #1676b8;
}

.activity-details .MuiButtonBase-root.Mui-selected:hover {
    color: #fff !important;
    border: 1px;
    background: #1676b8;
}

.hoteltab {
    border-bottom: 1px solid #ddd;
}

.view-images-btn{
    float: right !important;
    padding: 5px 0 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #1676b8 !important;
    background: #fff !important;
}

.view-images-btn:hover{
    background: #fff;
}

.slideimgbox .slick-prev:before, .slick-next:before {
    font-size: 32px !important;
    opacity: 1 !important;
    color: white !important;
}
.slideimgbox .slick-prev {
    left: -40px !important;
}

.activity-modal button{
    position: absolute;
}

.preview-modal .MuiPaper-root{
    max-width: 100%;
}

.close-modal-btn{
    float: right;
    right: 0%;
    top: 10px;
    z-index: 999999;
    background-color: red !important;
    color: #fff !important;
    font-size: 10px !important;
    padding: 4px !important;
    font-weight: 800;
}

.package-list-ul-1 ul{
    list-style: none;
}

.package-list-ul-1 ul li{
    font-size: 13px;
    margin-bottom: 5px;
    /* display: flex; */
}

.package-list-ul-1 ul li::before{
    content: "\2714";
    font-family: FontAwesome;
    display: inline-block;
    background: #4caf50;
    color: #fff;
    font-weight: 300;
    font-size: 8px;
    padding: 0px 3px;
    width: 12px ;
    height: 12px;
    margin-right: 10px;
    margin-top: 3px;
}

.package-list-ul-2 ul{
    list-style: none;
}

.package-list-ul-2 ul li{
    font-size: 13px;
    margin-bottom: 5px;
    /* display: grid; */
}

.package-list-ul-2 ul li::before{
    content: "\2716";
    font-family: FontAwesome;
    display: inline-block;
    background: red;
    color: #fff;
    font-weight: 300;
    font-size: 8px;
    padding: 0px 3px;
    margin-right: 10px;
    width: 12px ;
    height: 12px;
    margin-top: 3px;
}



.cancel-hd button{
    position: absolute;
}

.cancel-db h5{
    padding: 0px 15px;
    font-size: 18px;
}

.cancel-policy-db ul li{
    font-size: 13px;
}

.package-highlights-list-ul-1 ul li::before {
    content: "\f058";
    font-family: FontAwesome;
    display: inline-block;
    color: #1676b8;
    font-weight: 300;
    font-size: 15px;
    padding: 0px 3px;
    width: 12px;
    height: 12px;
    margin-right: 10px;
}
.package-highlights-list-ul-1 ul li{
    padding-bottom: 3px;
}

.package-highlights-list-ul-1 ul {
    list-style: none;
    padding: 0;
    font-size: 14px;
}
.sliderBox{
    margin: 0px !important;
}
.image-gallery-slides img{
    max-height: 500px;
    height: 500px;
    width: 100%;
}
.image-gallery-slide img {
    width: 900px !important;
    object-fit: cover !important;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 70px;
}

.package-highlights span {
    width: 100%;
    line-height: 1.2em;
    height: 5.7em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin-bottom: 0;
}

.details-skltn-mob{
    display: none;
}


.faq-search-box{
    text-align: right;
    margin-bottom: 15px;
}


.costing_li{
    border: 3px solid #eee;
    padding: 5px !important;
}

.tlt-input-traveller {
    font-size: 12.5px;
    margin-bottom: 0px;
    font-weight: 700;
    color: #000;
}

.travellerdetailsBox .MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 13px;
}

.act-select-wrapper{
    margin-top: 3%;
}
@media (max-width: 786px) {
   
    .singleimgbox img {
        height: 150px !important;
        width: 100% !important;
    }
    .multiimgbox img {
        height: 80px !important;
    }

    .sideimgboxleft img , .sideimgboxright img{
        height: 45px !important;
    }
    .selectactivity-container{
        padding: 0;
    }
    .faq-section h5{
        font-size: 17px;
    }
    .package-list-ul-1 ul , .package-list-ul-2 ul{
        list-style: none;
        padding: 0px 15px;
    }
    .image-gallery-slides img {
        max-height: 200px;
        height: 200px;
        width: 300px !important;
    }
    .tlttravellertype {
        font-size: 12px;
    }
    .act-select-wrapper .hotelrow {
        padding: 0 15px ;
    }
    .actfare-ttl-bold {
        font-size: 12px;
    }
    .fare-tlt {
        font-size: 17px;
    }
    .mt-xs-3{
        margin-top: 15px !important;
    }
    .reviewbtncontent {
        display: block !important;
        text-align: left;
    }
    .package-wrapper .btn-border{
        margin-bottom: 10px;
    }
    .travellerdetailsBox .three h1 {
        font-size: 17px !important;
    }

    .details-skltn-des{
        display: none;
    }
    .details-skltn-mob{
        display: block;
    }
    .image-gallery-thumbnails .image-gallery-thumbnails-container{
        white-space: initial !important;
    }
    .close-modal-btn {
        float: right;
        right: -10%;
        top: -15px;
        z-index: 999999;
        background-color: red !important;
        color: #fff !important;
        font-size: 10px !important;
        padding: 2px !important;
        font-weight: 800;
    }

    .inclution-list li, .exclution-list li, .inclution-list-simple li {
        margin-bottom: 6px;
        font-size: 12px !important;
    }
    .inclution-list svg {
        font-size: 11px !important;
    }
    .exclution-list svg {
        font-size: 11px !important;
    }
    .btn-selected-border-radius {
        font-size: 10px !important;
    }
    .font18 {
        font-size: 15px !important;
    }
    .activity-tab svg.MuiSvgIcon-root {
        font-size: 13px !important;
    }
    .tabsrow {
        margin: 0px 0px 15px !important;
    }

    .act-select-wrapper{
        margin-top: 30%;
    }
}

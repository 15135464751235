.betweenspace {
    width: 100%;
}
.flexset-skleton{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
}
.btnsktn {
    margin-top: 10px;
}
.flightsearchskeletonview {
    padding: 9px;
    margin-bottom: 6px;
    background: #fff;
    border-radius: 8px;
}
.headingskltn {
    padding: 2px 12px;
}
.pricelistsktn {
    margin-bottom: 8px;
}
.btnsktnfare {
    border-bottom: 1px solid #ccc;
}
.itmskt {
    margin-bottom: 3px;
}
.carddiv {
    width: 100%;
    height: 250px;
    padding: 15px;
    margin-bottom: 15px;
    background: #cccccc47;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.sktdiv {
    margin-bottom: 10px;
}
.tlt-range {
    display: flex;
    align-items: center;
}
.priceratelabel {
    display: flex;
    width: 100%;
    align-items: center;
}
.minprice {
    flex-basis: 50%;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
}
.maxprice {
    flex-basis: 50%;
    text-align: right;
    font-size: 13px;
    font-weight: 500;
}
.tltrangeprice {
    flex-basis: 45%;
    font-size: 14px;
    font-weight: 500;
}
.check-setbox {
    width: 100%;
    margin: 0!important;
}
.check-setbox span {
    font-size: 13px;
    font-weight: 500;
    margin-left: 7px;
}
.tlt-heading {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 8px;
    width: 100%;
}
.stops-filter {
    margin-top: 5px;
    width: 100%;
}
.stopsgroup, .timingroup {
    /* width: 100%; */
}
.stopsgroup-item, .timing-item {
    width: 100%;
    border: 1px solid #ccc !important;
    margin-bottom: 10px !important;
    padding: 3px !important;
    text-transform: capitalize !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    color: #444 !important;
}
.timing-item {
    font-size: 12px!important;
}
.icon-time span{
    font-size: 14px;
    padding: 5px;
}

.icosymbol{
    margin-top: -5px;
}
.icon-moon{
    margin-left: 3px;
}
.tltrange-filter{
    font-size: 18px;
    font-weight: 700;
}

.checkBoxshownet .MuiCheckbox-root{
    padding: 0px !important
}
.stops-filter {
    margin-top: 5px;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
.checkBoxshownet{
    padding:0px 10px;
}

.timingroup button{
    text-align: left;
    display: block;
    border: none !important;
    margin-bottom: 2px !important;
}

.timing-filter {
    margin-top: 10px;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.tlt-num {
    font-size: 9.5px;
    color: #fff;
    text-align: center;
}
.colorBox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.colorBox button{
    padding: 4px 10px !important;
    height: 30px !important;
    font-weight: 600;
}

.fare-color {
    padding: 0!important;
    height: 36px!important;
    margin-bottom: 10px !important;
    margin-right: 6px!important;
}
.colordefine1 {
    background: #28a745!important;
}
.colordefine2 {
    background: #ff0000!important;
}
.colordefine3 {
    background: #fc9e15!important;
}
.colordefine4 {
    background: #3A62D9!important;
}
.colordefine5 {
    background: #17a2b8!important;
}
.colordefine6 {
    background: #e91e63!important;
}
button.activecolor {
    opacity: 0.4;
}
.fare-identifier {
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}
.fare-identifier {
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}
.tlt-airline-tag {
    width: 100%;
    display: flex;
}
.tlt-clear {
    font-size: 13px;
    font-weight: 500;
    color: #999;
    cursor: pointer;
}
ul.airlines-ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.items-list-airlines {
    display: flex;
    padding: 0!important;
    align-items: center;
}
.tlt-items-tag {
    font-size: 13px;
    font-weight: 500;
    color: #1675b8;
    flex-basis: 50%;
    text-align: right;
}
.checkbox-itemsset {
    flex-basis: 70%;
    text-align: left;
}
.checkbox-itemsset label {
    margin: 0;
}
.checkbox-itemsset label span {
    font-size: 13px!important;
    font-weight: 500;
    color: #444;
    padding: 0px 10px 6px 0px;
}

.selected {
    background-color: #fc9e15;
}
.active-button {
    background: #fc9e15 !important;
    color: #fff !important;
    border: 1px solid #fc9e15 !important;
}

.timing-item.active-button {
    background-color: #dbeaf5 !important;
    color: #fff !important;
}

.PrivateValueLabel-circle-331{
    width: 70px!important;
    height: 32px!important;
    display: flex;
    transform: rotate(0deg)!important;
    align-items: center;
    border-radius: 3px!important;
    justify-content: center;
    background-color: currentColor;
    position: relative;
}
.PrivateValueLabel-label-3713 {
    color: #fff;
    transform: rotate(0deg)!important;
}

.selected_cell{
    background: #1675b8;
    color: #fff;
}
.check-whatsapp{
    margin: auto;
    margin-right: 0px !important;
}

.check-whatsapp span {
    font-size: 13px;
    font-weight: 500;
    margin-left: 7px;
    padding: 0px;
}

.footeraddress {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    flex-basis: 35%;
    height: auto !important;
}
.footer {
    background: #fff;
    /* margin-top: 100px; */

}
.footerimg img {
    width: 60%;
}
.footeraddress p {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 10px;
}
.copyright {
    font-size: 16px;
    font-weight: 500;
    color: #545454;
    width: 100%;
}
.socialicons {
    width: 100%;
    display: flex;
    border: none !important;
}
.linklist p {
    font-size: 14px;
    padding-right: 35px;
    text-align: justify;
}
.linklistul {
    flex-basis: 25%;
    height: auto !important;
}
.linklistul ul {
    list-style-type: none;
    font-size: 14px;
}
.linklistul ul li {
    line-height: 2;
}
.socialicons a svg {
    font-size: 26px;
    margin: 10px;
    color: #1675b8;
}
.footerimg {
    display: block;
    margin: auto;
}
.footerlogowrp{
    /* margin: auto; */
}
.linklist ul li {
    line-height: 2;
}

.flexboxlink {
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-top: 3px solid #1675b8;
}
.listlinkitemsbox {
    margin-top: 25px !important;
    display: flex;
    max-width: 100% !important;
}
.linklist {
    margin-top: 35px;
}

.footer .container-fluid{
    max-width: 1220px;
}
.whatsappchat:hover{
    color: #545454 !important;
}
.whatsappchat svg{
    color: green !important;
    font-size: 20px !important;
}
.footerPhoneNo svg{ 
    color: #1675b8;
    font-size: 20px !important;
}


.footeraddress.portal-link-btn a{
    color: #fff !important;
    font-size: 14px !important;
    padding: 10px 18px !important;
    text-transform: capitalize !important;
    border-radius: 24px !important;
    background: linear-gradient(to right, #eb4106, #f6882d ) !important;
    float: right;
    font-weight: 700 !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

@media (max-width: 786px) {
    .footeraddress.portal-link-btn a{
        float: left;
    }
}
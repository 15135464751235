body {
    background: #f2f2f2;
}

.login-input {
    width: 100%;
    margin: 0 auto;
}

.loginBox {
    margin: 25px;
}

h2.tlt-format {
    margin: 0;
    padding: 13px;
    background: #1675b8;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
}
h2.tlt-format span svg {
    font-size: 20px;
    margin-top: -4px;
    margin-right: 5px;
}
form.logintouser {
    padding: 18px;
    margin-top: 0px;
    height: auto;
    position: relative;
}
.Mui-error{
    margin-left: 0px ;
}
.tlt-item {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
}

.frg-sect {
    position: relative;
    margin-top: 0px;
}

.frgt-link {
    position: absolute;
    top: 0px;
    right: 11px;
    text-align: right;
    font-size: 17px;
    font-weight: 500;
    text-decoration: none!important;
}

.login-input input {
    font-size: 18px!important;
    padding: 10px 12px;
}

button.btn-submit {
    width: 100%;
    margin-top: 10px;
}

.ico-box ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
}

.ico-box2 ul {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
}

.ico-box {
    width: 65%;
    margin: 0px auto;
}

.ico-box2 {
    width: 54%;
    margin: 0px auto;
}

.icoBox {
    width: 120px;
    margin-bottom: 5px;
    display: block;
    font-size: 20px;
    font-weight: 500;
    color: #000;
    padding: 10px;
    text-align: center;
}

.box-prst {
    width: 176px;
    height: 125px;
    text-align: center;
    border: 2px solid #29a5de;
    padding: 10px 8px;
    border-radius: 30px;
    margin-bottom: 12px;
}

.tlt-services {
    width: 95%;
    margin: 0 auto;
}

.tlt-services ul {
    padding: 0;
    list-style-type: none;
    display: flex;
}

.tlt-services ul li {
    margin: 0 5px;
}

img.ico-img-mini {
    width: 50px;
    height: 56px;
}

h3.tlt-ico-h3 {
    margin: 0;
    font-size: 15px;
    margin-bottom: 5px;
    color: #29a5de;
}

img.icostatic {
    width: 65px;
    height: 70px;
}

h2.tlt-link {
    text-align: right;
    margin-right: 29px;
    font-size: 20px;
    font-weight: 700;
    color: #e91e63;
}

span.linkcreateaccount {
    margin-left: 20px;
    font-weight: 500;
    color: #1675b8;
}

.header-logo {
    width:25%;
    padding: 8px 0px;
}

.account-header-bg {
    width: 100%;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 99;
}

.ico-subtlt {
    font-size: 11px;
    color: #29a5de;
}

.ancor-link {
    text-decoration: none!important;
    cursor: pointer;
}

.popupHeader {
    padding: 16px 24px 0 24px!important;
}

.popupHeader h6 {
    font-size: 22px;
    font-weight: 600;
}

h3.subtlt-popup {
    margin: 0;
    /* margin-bottom: 20px; */
    font-size: 18px;
    font-weight: 500;
    padding: 0 9px;
    margin-top: 15px;
}

.smalltxt {
    padding: 8px 12px;
    font-size: 14px!important;
    color: #a5a5a5;
}

.inputforgot {
    width: 95%;
    margin: 8px 0!important;
    margin-left: 10px!important;
}

.inputforgot input {
    width: 100%;
    padding: 12px;
}

.inputforgot input::placeholder {
    font-size: 14.5px;
}

.popupBtn {
    width: 100%;
    margin: 0 19px!important;
    margin-bottom: 13px!important;
}

.snackbarcustomeshow {
    position: absolute!important;
    top: -14%!important;
    bottom: unset!important;
}
.snackbarcustomeshow .MuiSnackbarContent-root{
    background-color: red;
}

span.errormsg {
    padding: 9px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    /* width: 100%; */
    color: red;
    /* background: red; */
}

span.successmsg {
    padding: 9px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    /* width: 100%; */
    color: green;
    /* background: red; */
}

.link-user{
    margin-top: 24px;
}
.useraccountview{
    min-height: calc(100vh - 75px - 145px);
}
.captchaSection {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}
.captchaImg {
    height: 20px;
    width: 20px;
    margin : 5px;
}

.inputButton{
    position: relative !important;
    padding: 10px 0px !important;
}

.inputButton button{
    font-size: 15px;
}

.logintouser .MuiGrid-container{
    width: 100%;
    margin: 0px;
}

.gst-option{
    display: inline-block !important;
    padding: 10px;
}

.gst-option label .MuiTypography-root {
    font-size: 16px;
}

.gst-option label svg {
    width: 1.3rem;
}

.logintokencontainer{
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
    align-items: center;
    padding-top: 15%;
}

.navbar-items {
    width: 60%;
}

nav ul.items-navbar {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
}

.navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 12px;
}

ul.items-navbar li a {
    font-size: 15px;
    color: #000;
    text-decoration: none;
    font-weight: 500;
}

ul.items-navbar li {
    margin: 0 7px;
    padding: 10px;
    text-align: center;
}

.user_section_notification {
    width: 100%;
    text-align: end;
    margin-top: 25px;
    position: relative;
}

.flight-ico {
    transform: rotate(45deg);
}
.itw-logo {
    width: 40%;
}

.logo {
    text-align: center;
    padding-top: 0px;
    display: flex;
    flex-basis: 10%;
    width: 25%;
}

.headerBox {
    background-color: #fff!important;
    border-bottom: 3px solid #fc9e15;
}

ul.items-navbar li a span svg {
    font-size: 20px;
}

svg.MuiSvgIcon-root {
    font-size: 20px;
}

button.btn-drop {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
}

button.btn-notify {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
}

.top-header-dropdown {
 
}
.balance-nav{
    width: 100%;
    display: flex;
}
.balanced-view {
    padding: 0px 10px;
    font-size: 13px;
    font-weight: 500;
    width: auto;
    color: #fc9e15;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #ccc;
}

.dropdown-profile {
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    position: relative;
}

.notify-cnt {
    width: 100%;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 30%);
}

.notify_show {
    display: block;
}

.notify_hide {
    display: none;
}

.notify-items {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 7px 10px;
    cursor: pointer;
}

.notify-items:hover {
    background: beige;
}

.notifycontenthover {
    position: absolute;
    width: 300px;
    right: 0;
    z-index: 9;
}

.higlighttxt {
    font-size: 13px;
    padding: 0 5px;
    white-space: nowrap;
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Blurtxt {
    font-size: 11px;
    padding: 0 5px;
}

.notify-viewmore {
    padding: 15px;
    text-align: center;
}

.viewmore {
    font-size: 15px;
    color: #1675b8;
}

.blc-list {
    background: #fff;
    padding: 5px;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 30%);
    right: 0;
    top: 34px;
    border-radius: 8px;
}

.itemblc {
    display: flex;
    align-items: center;
    padding: 7px 10px;
}

.blnc-items-l {
    flex-basis: 50%;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    text-align: start;
}

.blnc-items-r {
    flex-basis: 50%;
    text-align: end;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.blnc-items-r.balance-price {
    color: red;
}

.balance_show {
    display: block;
}

.balance_hide {
    display: none;
}

.profile_view {
    display: flex;
}

.tltprofile {
    flex-basis: 100%;
}

button.profile {
    font-size: 12px;
    padding: 4px 10px;
    text-align: left;
    width: max-content;
}

.viewdropdown {
    width: 270px!important;
    right: 1!important;
    top: 7px!important;
    left: -41px!important;
}

.profile-bg {
    background: #1675b8;
    padding: 10px;
}

.tlt-img-ico {
    width: 70px;
    height: 70px;
    border: 6px solid #9fcc3a;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    margin: 0 auto;
}

.tlt-img-ico img {
    width: 100%;
    object-fit: cover;
    text-align: center;
    border-radius: 50%;
    height: 100%;
}

.tlt-name {
    font-size: 13px;
    margin-top: 5px;
    color: #fff;
    font-weight: 700;
    text-align: right;
}

.activedate {
    font-size: 13px;
    color: #fff;
    padding-bottom: 10px;
}

.btnadmin {
    width: 100%;
    text-align: left;
    font-size: 14px;
}

.link-contact {
    display: flex;
    text-align: center;
}

.contactnumber {
    text-align: right;
    font-size: 13px;
}

.contactmail {
    text-align: right;
    font-size: 13px;
    text-transform: lowercase;
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-end !important;
    padding: 0 !important;
}

button.btnadminlink {
    font-size: 10px!important;
    padding: 4px 8px!important;
}

.link-contact {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 4px 10px;
    background: azure;
}
.profilenChangespassword a {
    display: block;
    text-align: left;
}
ul.setlogout {
    padding: 0;
}
button.btnprofileset {
    padding: 5px 20px;
    font-size: 14px;
    text-transform: capitalize;
    width: 100%;
}
button.btnprofileset span {
    justify-content: flex-start;
}
button.changebtn {
    padding: 15px 20px !important;
    font-size: 15px;
    text-transform: capitalize;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: block !important;
    text-align: left;
    color: #000;
    font-weight: 500;
}
button.changebtn span {
    justify-content: flex-start;
}
button.linklogout {
    padding: 15px 20px !important;
    font-size: 15px;
    text-transform: capitalize;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: block !important;
    text-align: left;
    color: #000;
    font-weight: 500;
}
button.linklogout span {
    justify-content: flex-start;
}
ul a li.linklogout {
    font-size: 15px;
    color: #000;
    font-weight: 500;
    text-align: center!important;
    width: 86px;
    margin: 0 auto;
}

/***updated header css**/

.header {
    background-color: #fff;
    width: 100%;
    margin: 0;
    padding: 2px 30px;
    border-bottom: 3px solid #9fcc3a;
}
.Main-content-header {
    z-index: 15;
    position: sticky;
    top: 0;
}
.headercol {
    padding: 0!important;
}

nav.NavbarHeader.navbar.navbar-expand-lg.navbar-light {
    background: #fff!important;
    padding: 0.5rem 1.5rem;
}

.navbar-light .navbar-nav .nav-link {
    color: #000!important;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #1675b8!important;
    font-weight: 500;
}

a.navbaritems.nav-link {
    padding: 0 12px!important;
}

.dropdown-toggle::after {
    display: none!important;
}

.dropdownitem.nav-item.dropdown a {
    margin-left: 5px;
    padding: 0 5px!important;
}
.dropdownitem.nav-item.dropdown a li {
    font-size: 15px;
}

a.recharge{
    margin-right: 10px !important;
    padding: 5px !important;
    font-size: 14px;
    font-weight: 500;
}

/******updated header css******/
.rc-menu{
    z-index: 999;
}
button.rc-menu-button.upadtedmenuitems {
    outline: none;
    border: none;
    background: transparent;
    font-weight: 500;
    margin: 0 6px;
    font-size: 16px;
    display: flex;
}

button.rc-menu-button.upadtedmenuitems svg.MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 5px;
    margin-top: 2px;
}

a.nav-link {
    font-weight: 500!important; 
    background: transparent;
    font-size: 13px;
}

.tlt-id{
    font-size: 13px;
    color: #fff;
    text-align: right;
    padding: 0px 7px;
}
.btnprofileset{
    color: #9fcc3a;
    font-weight: 700;
    text-align: right;
    padding: 0px 7px;
    margin-bottom: 0px;
}

.navbar-menu{
    margin-right: 2%;
}

.logoContainer{
    width: 20%;
}

.badge-custom-flight .MuiBadge-badge {
    font-size: 10px;
    height: 15px;
    background: #1675b8;
}
.top-header-dropdown{
    display: flex;
}

.header-topup {
    padding: 3.2px 10px;
    border-radius: 3px;
    margin-right: 10px;
    box-shadow: 1px 2px 4px -1px #ccc;
}

.header-topup img{
    width: 27px;
    border-radius: 10px;
}

.header-topup span{
    font-size: 14.5px;
    font-weight: 600;
    background: -webkit-linear-gradient(#000, #0977bc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.box-header-clickaway{
    width: 100%;
}
@keyframes blinking {
    0% {
      background-color: #1675b83b;
      border: 2px solid #fee9d9;
    }
    50% {
        background-color: #fff;
        border: 2px solid #1675b83b;
      }
    100% {
      background-color: #1675b83b;
      border: 2px solid #fff;
    }
  }
  #blink {
    animation: blinking 4s  infinite;
    padding: 2px;
  }

  .badge-custom-flight-trending .MuiBadge-badge {
    font-size: 10px;
    height: 15px;
    background:red;
}

@media (max-width: 786px){
    .Main-content-header {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .navbar-collapse.collapse.show{
        position: absolute;
        background: #fff;
        width: 100%;
        top: 75px;
        right: 0px;
        box-shadow: 2px 4px 7px 2px #ccc;
        padding: 20px;
        text-align: center;
        z-index: 999;
    }

    .navbar-collapse.show{
        display: flex !important;
        align-items: flex-start !important;
        flex-flow: wrap-reverse;
    }
    .navbar-menu{
        margin-right: 15%;
        margin-left: 0px !important;
        text-align: left;
        align-items: baseline;
    }
    .tlt-name {
        font-size: 12px;
    }
    .top-header-dropdown {
        text-align: center;
        z-index: 9;
    }
    .itemblc {
        display: flex;
        align-items: center;
        padding: 4px 0px;
    }
    .navbar-expand{
        justify-content: flex-start !important;
        padding: 20px !important;
    }
    .navbar-expand .navbar-nav {
        flex-direction: column !important;
    }

    .header-topup {
        width: max-content;
    }
    .box-header-clickaway{
        width: 70%;
        text-align: right;
    }
}